import React from 'react'
import SEO from '../components/App/SEO'
import Layout from '../components/App/Layout'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import { graphql } from "gatsby";

let year = new Date();

const Tos = ({ data }) => {
  let result = data.allMarkdownRemark.edges[0].node.html
  return (
    <Layout env={data.site.siteMetadata.env}>
        <Navbar />
      <SEO
        postTitle="Terms Of Service | BlueMail App"
        postDescription="Terms Of Service Blue Mail"
        postImage=""
        postURL="tos"
        postSEO
      />
      <div className="template-page bg-nav">
      <div className="container">
        <div
          style={{ color: '#000' }}
          dangerouslySetInnerHTML={{ __html: result }}
        />
        <hr />
        <p><a style={{color: '#1AA3FF'}} href="https://blix.net">© {year.getFullYear()} Blix Inc.</a></p>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Tos

export const pageQuery = graphql`
  query TosNewIndexQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "tos" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            templateKey
          }
        }
      }
    }
    site {
      siteMetadata {
        env
      }
    }
  }
`
